<template>
  <div class="dashboard-input dashboard__stats-block main-container">
    <div class="row">
      <div class="col-lg-27">
        <h2 class="h2 d-inline-block">
          {{ 'Input' | translate }}
        </h2>
      </div>
    </div>
    <div class="row align-items-end text-center">
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Cows in Herd' | translate }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.herdSize"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              /> 
      </div>
      <div class="col-12 col-md">
        <label class="mb-1 dashboard-input__field">
          {{ 'Freshening Rate' | translate }}

          <!-- MODAL INFO COMPONENT -->
          <modal-info
            value="freshening-rate"
            class="dashboard-input__modal-info"
          >
            <template slot="modal-content">
              <h2 class="h2 pb-2 pt-3 px-3 text-center">
                {{ 'Freshening Rate' | translate }}
              </h2>
              <p class="px-3">
                {{
                  'Assumes that the herd calves more cows than the average herd size. Typically herds calve 4% more cows than the herd size. This is entered as a default rate (1.04). You may enter your rate as a decimal value.'
                  | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                >
                  {{ 'Cancel' | translate }}
                </a>
              </div>
            </template>
          </modal-info>
        </label>
        
        <currency-input
            v-model="evaluation.data.freshening_rate"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Length of Calving Period' | translate }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.length_in_calving_pen"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Length of Dry Period' | translate }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.length_of_dry_period_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Far Off (Days)' | translate }}
        </label>
        <input
          type="number"
          disabled
          :value="
            evaluation.data.length_of_dry_period_days -
              evaluation.data.close_up_days
          "
          class="form-control text-center"
        />
        <!-- <currency-input
            disabled
            v-model="evaluation.data.length_of_dry_period_days -evaluation.data.close_up_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              /> -->
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Close-Up (Days)' | translate }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.close_up_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{ 'Maternity (Days)' | translate }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.maternity_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">{{ 'Fresh (Days)' | translate }}</label>
        <currency-input
            disabled
            v-model="evaluation.data.fresh_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
      <div class="col-12 col-md">
        <label class="mb-1">
          {{
            ('Yield (305 days, ' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
              ')')
              | translate
          }}
        </label>
        <currency-input
            disabled
            v-model="evaluation.data.yield_305_days"
            :options="{
              currency: 'USD',
              precision: {min: 0, max :2},
              currencyDisplay: 'hidden',
            }"
              />
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfo from '@/components/misc/modal-info.vue';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  name: 'DashboardInput',
  props: {
    evaluation: Object,
  },
  components: {
    ModalInfo,
    CurrencyInput
  },
  created() {
    if (!this.evaluation.data.freshening_rate) {
      this.evaluation.data.freshening_rate = 1.04;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';

.dashboard-input {
  &__field {
    position: relative;
  }

  &__modal-info {
    position: absolute;
  }
}
</style>
