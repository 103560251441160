var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-input dashboard__stats-block main-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-27"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(" "+_vm._s(_vm._f("translate")('Input'))+" ")])])]),_c('div',{staticClass:"row align-items-end text-center"},[_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Cows in Herd'))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.herdSize),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "herdSize", $$v)},expression:"evaluation.data.herdSize"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1 dashboard-input__field"},[_vm._v(" "+_vm._s(_vm._f("translate")('Freshening Rate'))+" "),_c('modal-info',{staticClass:"dashboard-input__modal-info",attrs:{"value":"freshening-rate"}},[_c('template',{slot:"modal-content"},[_c('h2',{staticClass:"h2 pb-2 pt-3 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")('Freshening Rate'))+" ")]),_c('p',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm._f("translate")('Assumes that the herd calves more cows than the average herd size. Typically herds calve 4% more cows than the herd size. This is entered as a default rate (1.04). You may enter your rate as a decimal value.'))+" ")]),_c('div',{staticClass:"row modal-bottom pb-3"},[_c('a',{staticClass:"btn-cancel-modal d-flex justify-content-center",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Cancel'))+" ")])])])],2)],1),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.freshening_rate),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "freshening_rate", $$v)},expression:"evaluation.data.freshening_rate"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Length of Calving Period'))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.length_in_calving_pen),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "length_in_calving_pen", $$v)},expression:"evaluation.data.length_in_calving_pen"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Length of Dry Period'))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.length_of_dry_period_days),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "length_of_dry_period_days", $$v)},expression:"evaluation.data.length_of_dry_period_days"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Far Off (Days)'))+" ")]),_c('input',{staticClass:"form-control text-center",attrs:{"type":"number","disabled":""},domProps:{"value":_vm.evaluation.data.length_of_dry_period_days -
            _vm.evaluation.data.close_up_days}})]),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Close-Up (Days)'))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.close_up_days),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "close_up_days", $$v)},expression:"evaluation.data.close_up_days"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Maternity (Days)'))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.maternity_days),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "maternity_days", $$v)},expression:"evaluation.data.maternity_days"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm._f("translate")('Fresh (Days)')))]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.fresh_days),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "fresh_days", $$v)},expression:"evaluation.data.fresh_days"}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")(('Yield (305 days, ' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'kg')) + ')')))+" ")]),_c('currency-input',{attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.evaluation.data.yield_305_days),callback:function ($$v) {_vm.$set(_vm.evaluation.data, "yield_305_days", $$v)},expression:"evaluation.data.yield_305_days"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }