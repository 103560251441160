var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-typed-groups col-md-12 row align-items-end text-center align-content-start"},[_c('div',{staticClass:"dashboard-typed-groups__heading row no-gutters w-100 align-items-start mb-md-4"},[_c('div',{staticClass:"col-24 col-md-12 text-left"},[_c('h2',{staticClass:"h2 d-inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.translateType(_vm.groupType))+" "+_vm._s(_vm._f("translate")('Cow Evaluation'))+" ")])]),(_vm.groups.length > 1)?_c('div',{staticClass:"col-24 col-md-12 d-flex justify-content-md-end my-2 my-md-0"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupId),expression:"groupId"}],staticClass:"form-control dashboard__select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.groupId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm._f("translate")('Select')))]),_vm._l((_vm.groups),function(group,idx){return _c('option',{key:group.id,domProps:{"value":idx}},[_vm._v(" "+_vm._s(group.name)+" ")])})],2)]):_vm._e()]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.groupType === 'fresh' ? '% Cows Rumen Fill < 3' : '% Cows Rumen Fill < 4'))+" ")]),_c('currency-input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.cows_rumen_fill),attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.groupAssessor.cows_rumen_fill),callback:function ($$v) {_vm.$set(_vm.groupAssessor, "cows_rumen_fill", $$v)},expression:"groupAssessor.cows_rumen_fill"}})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('% of Cows with Manure Score < 3'))+" ")]),_c('currency-input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.cows_manure_score),attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.groupAssessor.cows_manure_score),callback:function ($$v) {_vm.$set(_vm.groupAssessor, "cows_manure_score", $$v)},expression:"groupAssessor.cows_manure_score"}})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('% of Cows with BCS ≤2.5 or ≥3.75, 1st lact'))+" ")]),_c('currency-input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.body_condition_score_1),attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.groupAssessor.body_condition_score_1),callback:function ($$v) {_vm.$set(_vm.groupAssessor, "body_condition_score_1", $$v)},expression:"groupAssessor.body_condition_score_1"}})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('% of Cows with BCS ≤2.5 or ≥3.75, ≥2nd lact'))+" ")]),_c('currency-input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.body_condition_score_2),attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.groupAssessor.body_condition_score_2),callback:function ($$v) {_vm.$set(_vm.groupAssessor, "body_condition_score_2", $$v)},expression:"groupAssessor.body_condition_score_2"}})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Refusal %'))+" ")]),_c('currency-input',{staticClass:"form-control text-center border-2",class:_vm.classInputRefusal(_vm.groupAssessor.refusal),attrs:{"disabled":"","options":{
            currency: 'USD',
            precision: {min: 0, max :2},
            currencyDisplay: 'hidden',
          }},model:{value:(_vm.groupAssessor.refusal),callback:function ($$v) {_vm.$set(_vm.groupAssessor, "refusal", $$v)},expression:"groupAssessor.refusal"}})],1),(_vm.typeIsFresh)?[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Feed Available after Milking?'))+" ")]),_c('input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.is_feed_available_af_milking),attrs:{"disabled":""},domProps:{"value":_vm.translateBoolValues(_vm.groupAssessor.is_feed_available_af_milking)}})]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Checks Done After Milking?'))+" ")]),_c('input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.checks_af_milking),attrs:{"disabled":""},domProps:{"value":_vm.translateBoolValues(_vm.groupAssessor.checks_af_milking)}})]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Time Spent Checking Fresh Cows (hr)'))+" ")]),_c('input',{staticClass:"form-control text-center border-2",class:_vm.classTimeChecking(_vm.groupAssessor.time_checking_hr),attrs:{"disabled":""},domProps:{"value":_vm.$t(_vm.comparativeValues.hr[_vm.groupAssessor.time_checking_hr])}})]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("translate")('Check Appetite?'))+" ")]),_c('input',{staticClass:"form-control text-center border-2",class:_vm.classInput(_vm.groupAssessor.is_appetite_checked),attrs:{"disabled":""},domProps:{"value":_vm.translateBoolValues(_vm.groupAssessor.is_appetite_checked)}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }